import { createGlobalStyle } from 'styled-components'
import ldColor from '../utilities/lightenDarkenColor'

import fontLightWoff from '../assets/fonts/NotoSansJP-Light-Alphabetic.woff'
import fontLightWoff2 from '../assets/fonts/NotoSansJP-Light-Alphabetic.woff2'
import fontBoldWoff from '../assets/fonts/NotoSansJP-Black-Alphabetic.woff'
import fontBoldWoff2 from '../assets/fonts/NotoSansJP-Black-Alphabetic.woff2'
import fontDisplayWoff from '../assets/fonts/InterstateBlackCondensed.woff'
import fontDisplayWoff2 from '../assets/fonts/InterstateBlackCondensed.woff2'
import fontNumbersWoff from '../assets/fonts/PlayfairDisplay-Bold.woff'
import fontNumbersWoff2 from '../assets/fonts/PlayfairDisplay-Bold.woff2'

const Typography = createGlobalStyle`
  @font-face {
    font-family: 'NotoSans';
    src:  url(${fontLightWoff}) format('woff'),
          url(${fontLightWoff2}) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'NotoSans';
    src:  url(${fontBoldWoff}) format('woff'),
          url(${fontBoldWoff2}) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Interstate';
    src:  url(${fontDisplayWoff}) format('woff'),
          url(${fontDisplayWoff2}) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Playfair';
    src:  url(${fontNumbersWoff}) format('woff'),
          url(${fontNumbersWoff2}) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  html {
    font-family: var(--body-font-family);
    color: var(--text-color);
  }
  p {
    line-height: 1.6em;
  }
  h1 {
    font-size: 3.6rem;
  }
  h2 {
    font-size: 3.6rem;
  }
  h3 {
    font-size: 2rem;
    @media (max-width: 767px) {
      font-size: 2.4rem;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: var(--header-font-family);
    color: var(--primary-color);
    font-weight: bold;
  }
  a {
    color: ${ldColor(`#1C0D03`, +160)};
    color: #fff;
    text-decoration: none;
    transition: all 0.1s ease;
    &:hover {
      color: var(--rust);
    }
  }

  .text-center {
    text-align: center;
  }
`

export default Typography
