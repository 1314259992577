import { createGlobalStyle } from 'styled-components'

const ModalStyles = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    background-color: rgba(0,0,0,0.6);
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
`

export default ModalStyles
