import { createGlobalStyle } from 'styled-components'
import ldColor from '../utilities/lightenDarkenColor'

const GlobalStyles = createGlobalStyle`
  :root {
    --brown: #1C0D03;
    --rust: #CE4F2F;
    --gold: #DDB031;
    --teal: #59C1C1;
    --primary-color: var(--brown);
    --primary-color-hover: ${ldColor(`#1C0D03`, -12)};
    --primary-color-dark: ${ldColor(`#1C0D03`, -12)};
    --secondary-color: var(--rust);
    --secondary-color-hover: ${ldColor(`#CE4F2F`, -12)};
    --disabled-color: #999;
    --complete-color: var(--gold);
    --text-color: #999;
    --primary-color-trans-5: rgba(28,92,166, 0.05);
    --primary-color-trans-15: rgba(28,92,166, 0.15);
    --base-font-size: 10px;
    --box-shadow: 0 0 20px var(--primary-color-trans-15);
    --gap-layout: 2rem;
    --gap-items: 2rem;
    --section-spacing: 8rem;
    --panel-border-radius: 6px;
    --input-border-radius: 4px;
    --header-font-family: Interstate, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --body-font-family: NotoSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --numbers-font-family: Playfair, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    @media (max-width: 767px) {
      --base-font-size: 8px;
      --section-spacing: 4rem;
    }
  }
  * {
    box-sizing: border-box;
  }
  html {
    background-color: var(--primary-color-dark);
    font-size: var(--base-font-size);
    scroll-behavior: smooth;
  }

  body {
    font-size: 1.6rem;
    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  .button, button {
    display: inline-block;
    background: var(--rust);
    color: #fff;
    font-weight: bold;
    border: 0;
    padding: 2rem 2.4rem;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      color: #fff;
      background: ${ldColor(`#CE4F2F`, -12)};
      text-decoration: none;
    }
    &:disabled, &[disabled] {
      background: var(--disabled-color);
      &.complete {
        background: var(--complete-color);
        cursor: default;
      }
    }
  }

  /* Scrollbar Styles */ /*
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }*/

  hr {
    border: 0;
    margin: 0;
    height: 1px;
    background-color: var(--primary-color-trans-15);
  }

  img {
    max-width: 100%;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    ${'' /* image-rendering: pixelated; */}
  }

  .panel {
    background-color: white;
    padding: 3rem;
    box-shadow: var(--box-shadow);
    border-radius: var(--panel-border-radius);
  }
`

export default GlobalStyles
