import React from 'react'
import styled from 'styled-components'
import useForm from '../utilities/useForm'
import useContact from '../utilities/useContact'

const ContactStyle = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  width: 50vw;
  margin: 0 auto;
  .call {
    text-align: center;
    margin: 0 auto;
  }
  .or {
    color: var(--rust);
    text-align: center;
  }
  @media (max-width: 1000px) {
  }
  @media (max-width: 800px) {
    padding: 3rem 2rem;
    width: 100vw;
  }
`

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .form-row {
    display: grid;
    --col-width: 300px;
    grid-template-columns: repeat(auto-fill, minmax(var(--col-width), 1fr));
    gap: 2rem;
    &.double {
      --col-width: 600px;
    }
  }
  .actions {
    button {
      display: block;
      margin: 0 auto;
    }
  }
  @media (max-width: 1000px) {
  }
  @media (max-width: 800px) {
    .form-row {
      &.double {
        --col-width: 300px;
      }
    }
  }
`

export default function ContactForm() {
  const { values, updateValue } = useForm({
    name: '',
    email: '',
    message: '',
    honey: '',
  })
  const { error, loading, message, submitted, submitContactForm } = useContact({
    values,
  })
  return (
    <ContactStyle>
      <a href="tel:4053887024" className="button call">
        Call: (405) 388-7024
      </a>
      <div className="or">- OR -</div>
      <FormStyle onSubmit={submitContactForm}>
        <div className="form-row">
          <label htmlFor="name">
            Name
            <input
              type="text"
              name="name"
              id="name"
              placeholder="e.g. John Smith"
              value={values.name}
              onChange={updateValue}
              required
              disabled={loading || submitted}
            />
          </label>
          <label htmlFor="email">
            Email
            <input
              type="text"
              name="email"
              id="email"
              placeholder="e.g. john.smith@email.com"
              value={values.email}
              onChange={updateValue}
              required
              disabled={loading || submitted}
            />
          </label>
        </div>
        <div className="form-row double">
          <label htmlFor="message">
            Message
            <textarea
              name="message"
              id="message"
              rows="5"
              placeholder="e.g. I'm interested in a custom home..."
              value={values.message}
              onChange={updateValue}
              required
              disabled={loading || submitted}
            />
          </label>
        </div>
        <input
          type="text"
          name="honey"
          id="honey"
          value={values.honey}
          onChange={updateValue}
          className="honey"
        />
        <div className="actions">
          <button
            type="submit"
            disabled={loading || submitted}
            className={submitted ? 'complete' : ''}
          >
            <span aria-live="assertive" aria-atomic="true">
              {loading ? 'Sending message...' : ''}
            </span>
            {loading ? '' : submitted ? 'Message Sent' : 'Send Message'}
          </button>
        </div>
        {error ||
          (message && (
            <div className="text-center">{error || message || ''}</div>
          ))}
      </FormStyle>
    </ContactStyle>
  )
}
