import React from 'react'
import styled from 'styled-components'
import ldColor from '../utilities/lightenDarkenColor'

const ExpanderStyle = styled.div`
  .header {
    padding: 2rem;
    display: flex;
    cursor: pointer;
    justify-content: center;
    transition: all 0.2s;
    &:hover {
      .title {
        color: var(--rust);
      }
      .icon {
        transform: translateY(3px);
        &.expanded {
          transform: translateY(-3px);
        }
      }
    }
  }
  .title {
    color: #fff;
    padding: 3px;
    flex: none;
  }
  .spacer {
    flex: 1;
  }
  .icon {
    color: var(--rust);
    padding: 3px;
    transition: all 0.2s;
  }
  .content {
    overflow: hidden;
    transition: all 0.3s ease;
    max-height: 0;
    background-color: ${ldColor(`#1C0D03`, +20)};
    box-shadow: inset 0 0 0.8rem rgb(0 0 0 / 70%);
    &.expanded {
      height: auto;
      max-height: 999px;
    }
  }
  @media (max-width: 991px) {
  }
`

export default function Expander({ title, children }) {
  const [expanded, setExpanded] = React.useState(false)
  const handleHeaderClick = () => {
    setExpanded(expanded => !expanded)
  }
  const handleKeyDown = () => {
    // setExpanded(expanded => !expanded)
  }
  return (
    <ExpanderStyle>
      <div
        className="header"
        onClick={handleHeaderClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className="title">{title}</div>
        <div className={`icon ${expanded ? 'expanded' : ''}`}>
          {expanded ? '▲' : '▼'}
        </div>
      </div>
      <div className={`content ${expanded ? 'expanded' : ''}`}>{children}</div>
    </ExpanderStyle>
  )
}
