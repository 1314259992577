import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Expander from './Expander'
import ContactForm from './ContactForm'
import LogoMark from '../assets/images/inline/van-parsons-oat.svg'

const FooterStyles = styled.footer`
  padding: 4rem 0;
  display: grid;
  gap: 2rem;
  .logo {
    margin: 0 auto;
    padding: 0;
    width: 120px;
  }
  .social {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  .contact {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
`

export default function Footer() {
  return (
    <FooterStyles>
      <Link to="#top" className="text-center">
        Back to top
      </Link>
      <Expander title="Contact">
        <ContactForm />
      </Expander>
      <LogoMark className="logo" />
      <span className="text-center">
        &copy; Van Parsons {new Date().getFullYear()}
      </span>
    </FooterStyles>
  )
}
