import React from 'react'
import styled from 'styled-components'
import 'normalize.css'
import Nav from './Nav'
import Footer from './Footer'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import SEO from './SEO'
import FormStyles from '../styles/FormStyles'
import ModalStyles from '../styles/ModalStyles'

const ContentStyles = styled.div`
  background: var(--primary-color-dark);
  width: 100%;
`
const TopLink = styled.div`
  position: absolute;
  top: 0;
`

export default function Layout({ children }) {
  return (
    <>
      <SEO />
      <GlobalStyles />
      <Typography />
      <FormStyles />
      <ModalStyles />
      <ContentStyles>
        <TopLink id="top" />
        <Nav />
        {children}
        <Footer />
      </ContentStyles>
    </>
  )
}
