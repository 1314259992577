import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import ldColor from '../utilities/lightenDarkenColor'

import Logo from '../assets/images/inline/van-parsons-text.svg'
import LogoMark from '../assets/images/inline/van-parsons-oat.svg'

const NavStyles = styled.nav`
  padding: 3rem;
  display: grid;
  grid-template-columns: 30rem auto;
  align-items: center;
  .logo {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 5%;
    align-items: center;
  }
  .logo-mark {
    max-width: 100%;
    opacity: 1;
  }
  .logo-text {
    width: 100%;
  }
  .links {
    margin: 0;
    padding: 0;
    list-style: none;
    justify-self: end;
    display: grid;
    grid-template-columns: repeat(4, max-content);
    gap: 2rem;
    .tagline {
      color: var(--rust);
    }
    a {
      padding: 1rem;
      font-weight: bold;
    }
  }
  @media (max-width: 991px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    justify-content: center;
    .logo {
      justify-content: center;
      margin-left: -5px; /* optically align */
    }
    .links {
      margin-top: 2rem;
      display: flex;
    }
  }
`

export default function Nav() {
  return (
    <NavStyles>
      <Link to="/" className="logo">
        <LogoMark className="logo-mark" />
        <Logo className="logo-text" />
      </Link>
      <ul className="links">
        <li className="tagline">Custom homes and furniture</li>
        <li>
          <Link to="/projects" title="Projects page">
            Projects
          </Link>
        </li>
        {/* <li>
          <Link to="/tags" title="Tags page">
            Tags
          </Link>
        </li> */}
      </ul>
    </NavStyles>
  )
}
