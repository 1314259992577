import { createGlobalStyle } from 'styled-components'

const FormStyles = createGlobalStyle`
  label {
    color: #fff;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
  input[type="text"],
  input[type="password"],
  input[type="url"],
  input[type="tel"],
  input[type="search"],
  input[type="number"],
  input[type="datetime"],
  input[type="email"],
  textarea {
    display: block;
    font-size: 1.6rem;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    margin-top: 0.4rem;
    border: 1px solid var(--primary-color-trans-15);
    border-radius: var(--input-border-radius);
    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .honey {
    display: none !important;
  }
`

export default FormStyles
